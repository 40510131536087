import { Company } from './../DTOs/company.dto';
import { APIService } from './API.service';
import { Component, OnInit } from '@angular/core';
import { typeWithParameters } from '@angular/compiler/src/render3/util';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  initial_lat = -34.9208;
  initial_lng = -57.9564;
  companies : Array<Company> = [];
  loaded = false;
  types : Array<any> = [];
  marker_selected_infoWindow = null;
  filtered_companies : Array<Company> = [];
  filtered_type_companies: Array<Company> = [];
  filter : String = 'name';
  map_zoom = 13;
  name_filter = 0;
  type_filter = 0;
  charging_flag = true; 

  constructor(private api: APIService) { }

  async ngOnInit(){
    await this.getCompanies();
    await this.createSubscription();
    await this.generateTypes();
    this.loaded = true;
  }

  async getCompanies(){
    var token;

    await this.api.ListCompanys().then(event => {
      this.companies = event.items;      
      token = event.nextToken;
    }).catch(e => {
      console.log("Error al obtener las companias: ", e)
    });

    while(token != null){
      await this.api.ListCompanys(null, null, token).then(event => {
        event.items.forEach(company => {
          this.companies.push(company);
        });
        token = event.nextToken;
      }).catch(e => {
        console.log("Error al obtener las companias: ", e)
      });
    }
    
    this.filtered_companies = this.companies;
    
  }

  async createSubscription(){
    this.api.OnCreateCompanyListener.subscribe((event:any) => {
      const newCompany = event.value.data.onCreateCompany;
      this.companies.push(newCompany);
    });
  }

  async generateTypes(){
    let type_arr = [];
    let initial_arr = [];
    let count;
    
    this.companies.forEach(company => {
      initial_arr.push(company.type);
    });

    let type_set = new Set(initial_arr);

    type_arr = Array.from(type_set);

    type_arr.forEach(t => {
      count = 0;
      count = initial_arr.reduce((acum, value) => (value === t ? acum + 1 : acum), 0);
      const type_object = {
        type:t.charAt(0).toUpperCase() + t.slice(1),
        total:count
      };
      this.types.push(type_object);
    });
  }

  async onMarkerClick(event){
    if(this.marker_selected_infoWindow != null){
      this.marker_selected_infoWindow._results[0].close();
    }
    this.marker_selected_infoWindow = event.infoWindow;    
    this.initial_lat = event.latitude;
    this.initial_lng = event.longitude;
  }

  async getCompanyFilteredByName(company){
    this.name_filter = 1;
    this.type_filter = 0;
    this.filtered_companies = [];
    this.marker_selected_infoWindow = null;
    this.filtered_companies.push(company);
    this.initial_lat = company.latitude;
    this.initial_lng = company.length;
    this.map_zoom = 16;
  }

  onChangeNameFilter(text){
    if(text == ""){
      if(this.name_filter == 1){
        this.filtered_companies = this.companies;
        this.map_zoom = 13;
        this.name_filter = 0;
        if(this.marker_selected_infoWindow != null){
          this.marker_selected_infoWindow._results[0].close();
          this.marker_selected_infoWindow = null;
        }
      }
    }
  }

  async getCompaniesByType(type){
    var t = type.toLowerCase().toString();
    var arreglo = [];
    var token = "";
    
    await this.api.ListCompanys({type:{eq:t}}).then(event => {
      arreglo = event.items;
      token = event.nextToken;     
    }).catch(e => {
      console.log("Error al obtener las companias: ", e)
    });

    while(token != null){
      await this.api.ListCompanys({type:{eq:t}},null,token).then(event => {
        event.items.forEach(company => {
          arreglo.push(company);
        });
        token = event.nextToken;    
      }).catch(e => {
        console.log("Error al obtener las companias: ", e)
      });
    }

    this.charging_flag = false;
    this.filtered_type_companies = arreglo;
  }

  cleanTypeFilter(){
    this.filtered_type_companies = [];
    this.charging_flag = true;
    if(this.type_filter == 1){
      this.filtered_companies = this.companies;
      this.map_zoom = 13;
      if(this.marker_selected_infoWindow != null){
        this.marker_selected_infoWindow._results[0].close();
        this.marker_selected_infoWindow = null;
      }
    }
  }

  getCompanyFilteredByType(company){
    this.filtered_companies = [];
    this.marker_selected_infoWindow = null;
    this.filtered_companies.push(company);
    this.initial_lat = company.latitude;
    this.initial_lng = company.length;
    this.map_zoom = 16;
    this.name_filter = 0;
    this.type_filter = 1;
  }
  
  cleanNameFilter(){
    if(this.name_filter == 1){
      this.filtered_companies = this.companies;
      this.map_zoom = 13;
      this.name_filter = 0;
      if(this.marker_selected_infoWindow != null){
        this.marker_selected_infoWindow._results[0].close();
        this.marker_selected_infoWindow = null;
      }
    }
  }

}


