/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from "@angular/core";
import API, { graphqlOperation, GraphQLResult } from "@aws-amplify/api-graphql";
import { Observable } from "zen-observable-ts";

export interface SubscriptionResponse<T> {
  value: GraphQLResult<T>;
}

export type CreateCompanyInput = {
  id?: string | null;
  aproved: number;
  name: string;
  type: string;
  latitude: number;
  length: number;
  addres?: string | null;
  url_website?: string | null;
  description?: string | null;
  sector?: string | null;
  owner_name?: string | null;
  owner_email?: string | null;
};

export type ModelCompanyConditionInput = {
  aproved?: ModelIntInput | null;
  name?: ModelStringInput | null;
  type?: ModelStringInput | null;
  latitude?: ModelFloatInput | null;
  length?: ModelFloatInput | null;
  addres?: ModelStringInput | null;
  url_website?: ModelStringInput | null;
  description?: ModelStringInput | null;
  sector?: ModelStringInput | null;
  owner_name?: ModelStringInput | null;
  owner_email?: ModelStringInput | null;
  and?: Array<ModelCompanyConditionInput | null> | null;
  or?: Array<ModelCompanyConditionInput | null> | null;
  not?: ModelCompanyConditionInput | null;
};

export type ModelIntInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null"
}

export type ModelStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export type ModelSizeInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type ModelFloatInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type Company = {
  __typename: "Company";
  id?: string;
  aproved?: number;
  name?: string;
  type?: string;
  latitude?: number;
  length?: number;
  addres?: string | null;
  url_website?: string | null;
  description?: string | null;
  sector?: string | null;
  owner_name?: string | null;
  owner_email?: string | null;
  createdAt?: string;
  updatedAt?: string;
};

export type UpdateCompanyInput = {
  id: string;
  aproved?: number | null;
  name?: string | null;
  type?: string | null;
  latitude?: number | null;
  length?: number | null;
  addres?: string | null;
  url_website?: string | null;
  description?: string | null;
  sector?: string | null;
  owner_name?: string | null;
  owner_email?: string | null;
};

export type DeleteCompanyInput = {
  id: string;
};

export type ModelCompanyFilterInput = {
  id?: ModelIDInput | null;
  aproved?: ModelIntInput | null;
  name?: ModelStringInput | null;
  type?: ModelStringInput | null;
  latitude?: ModelFloatInput | null;
  length?: ModelFloatInput | null;
  addres?: ModelStringInput | null;
  url_website?: ModelStringInput | null;
  description?: ModelStringInput | null;
  sector?: ModelStringInput | null;
  owner_name?: ModelStringInput | null;
  owner_email?: ModelStringInput | null;
  and?: Array<ModelCompanyFilterInput | null> | null;
  or?: Array<ModelCompanyFilterInput | null> | null;
  not?: ModelCompanyFilterInput | null;
};

export type ModelIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export type ModelCompanyConnection = {
  __typename: "ModelCompanyConnection";
  items?: Array<Company | null> | null;
  nextToken?: string | null;
};

export type CreateCompanyMutation = {
  __typename: "Company";
  id: string;
  aproved: number;
  name: string;
  type: string;
  latitude: number;
  length: number;
  addres?: string | null;
  url_website?: string | null;
  description?: string | null;
  sector?: string | null;
  owner_name?: string | null;
  owner_email?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateCompanyMutation = {
  __typename: "Company";
  id: string;
  aproved: number;
  name: string;
  type: string;
  latitude: number;
  length: number;
  addres?: string | null;
  url_website?: string | null;
  description?: string | null;
  sector?: string | null;
  owner_name?: string | null;
  owner_email?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteCompanyMutation = {
  __typename: "Company";
  id: string;
  aproved: number;
  name: string;
  type: string;
  latitude: number;
  length: number;
  addres?: string | null;
  url_website?: string | null;
  description?: string | null;
  sector?: string | null;
  owner_name?: string | null;
  owner_email?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type GetCompanyQuery = {
  __typename: "Company";
  id: string;
  aproved: number;
  name: string;
  type: string;
  latitude: number;
  length: number;
  addres?: string | null;
  url_website?: string | null;
  description?: string | null;
  sector?: string | null;
  owner_name?: string | null;
  owner_email?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ListCompanysQuery = {
  __typename: "ModelCompanyConnection";
  items?: Array<{
    __typename: "Company";
    id: string;
    aproved: number;
    name: string;
    type: string;
    latitude: number;
    length: number;
    addres?: string | null;
    url_website?: string | null;
    description?: string | null;
    sector?: string | null;
    owner_name?: string | null;
    owner_email?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type OnCreateCompanySubscription = {
  __typename: "Company";
  id: string;
  aproved: number;
  name: string;
  type: string;
  latitude: number;
  length: number;
  addres?: string | null;
  url_website?: string | null;
  description?: string | null;
  sector?: string | null;
  owner_name?: string | null;
  owner_email?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateCompanySubscription = {
  __typename: "Company";
  id: string;
  aproved: number;
  name: string;
  type: string;
  latitude: number;
  length: number;
  addres?: string | null;
  url_website?: string | null;
  description?: string | null;
  sector?: string | null;
  owner_name?: string | null;
  owner_email?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteCompanySubscription = {
  __typename: "Company";
  id: string;
  aproved: number;
  name: string;
  type: string;
  latitude: number;
  length: number;
  addres?: string | null;
  url_website?: string | null;
  description?: string | null;
  sector?: string | null;
  owner_name?: string | null;
  owner_email?: string | null;
  createdAt: string;
  updatedAt: string;
};

@Injectable({
  providedIn: "root"
})
export class APIService {
  async CreateCompany(
    input: CreateCompanyInput,
    condition?: ModelCompanyConditionInput
  ): Promise<CreateCompanyMutation> {
    const statement = `mutation CreateCompany($input: CreateCompanyInput!, $condition: ModelCompanyConditionInput) {
        createCompany(input: $input, condition: $condition) {
          __typename
          id
          aproved
          name
          type
          latitude
          length
          addres
          url_website
          description
          sector
          owner_name
          owner_email
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCompanyMutation>response.data.createCompany;
  }
  async UpdateCompany(
    input: UpdateCompanyInput,
    condition?: ModelCompanyConditionInput
  ): Promise<UpdateCompanyMutation> {
    const statement = `mutation UpdateCompany($input: UpdateCompanyInput!, $condition: ModelCompanyConditionInput) {
        updateCompany(input: $input, condition: $condition) {
          __typename
          id
          aproved
          name
          type
          latitude
          length
          addres
          url_website
          description
          sector
          owner_name
          owner_email
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCompanyMutation>response.data.updateCompany;
  }
  async DeleteCompany(
    input: DeleteCompanyInput,
    condition?: ModelCompanyConditionInput
  ): Promise<DeleteCompanyMutation> {
    const statement = `mutation DeleteCompany($input: DeleteCompanyInput!, $condition: ModelCompanyConditionInput) {
        deleteCompany(input: $input, condition: $condition) {
          __typename
          id
          aproved
          name
          type
          latitude
          length
          addres
          url_website
          description
          sector
          owner_name
          owner_email
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCompanyMutation>response.data.deleteCompany;
  }
  async GetCompany(id: string): Promise<GetCompanyQuery> {
    const statement = `query GetCompany($id: ID!) {
        getCompany(id: $id) {
          __typename
          id
          aproved
          name
          type
          latitude
          length
          addres
          url_website
          description
          sector
          owner_name
          owner_email
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCompanyQuery>response.data.getCompany;
  }
  async ListCompanys(
    filter?: ModelCompanyFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCompanysQuery> {
    const statement = `query ListCompanys($filter: ModelCompanyFilterInput, $limit: Int, $nextToken: String) {
        listCompanys(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            aproved
            name
            type
            latitude
            length
            addres
            url_website
            description
            sector
            owner_name
            owner_email
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCompanysQuery>response.data.listCompanys;
  }
  OnCreateCompanyListener: Observable<
    SubscriptionResponse<OnCreateCompanySubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateCompany {
        onCreateCompany {
          __typename
          id
          aproved
          name
          type
          latitude
          length
          addres
          url_website
          description
          sector
          owner_name
          owner_email
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateCompanySubscription>>;

  OnUpdateCompanyListener: Observable<
    SubscriptionResponse<OnUpdateCompanySubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateCompany {
        onUpdateCompany {
          __typename
          id
          aproved
          name
          type
          latitude
          length
          addres
          url_website
          description
          sector
          owner_name
          owner_email
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateCompanySubscription>>;

  OnDeleteCompanyListener: Observable<
    SubscriptionResponse<OnDeleteCompanySubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteCompany {
        onDeleteCompany {
          __typename
          id
          aproved
          name
          type
          latitude
          length
          addres
          url_website
          description
          sector
          owner_name
          owner_email
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteCompanySubscription>>;
}
