<mat-drawer-container class="example-container" autosize [hasBackdrop]="false">
  <mat-drawer #drawer mode="side">
    <mat-card>
      <mat-card-header>
        <div mat-card-avatar class="example-types-image"></div>
        <mat-card-title><strong><em>Tipos</em></strong></mat-card-title>
        <mat-card-subtitle>Empresas</mat-card-subtitle>
      </mat-card-header>
    </mat-card>
    <mat-accordion class="mat-class">
      <mat-expansion-panel *ngFor="let t of types" [value]="t.type" (opened)="getCompaniesByType(t.type)" (closed)="cleanTypeFilter()">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon aria-hidden="false" aria-label="Example home icon">engineering</mat-icon>
             {{t.type}} ({{t.total}})
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-selection-list #companies [multiple]="false">
          <mat-list-option *ngIf="charging_flag">
            Cargando...
          </mat-list-option>
          <mat-list-option *ngFor="let c of filtered_type_companies" [value]="c" (click)="getCompanyFilteredByType(companies.selectedOptions.selected[0]?.value)">
            {{c.name}}
          </mat-list-option>
        </mat-selection-list>
      </mat-expansion-panel>
    </mat-accordion> 
  </mat-drawer>
  <div class="example-sidenav-content">
    <mat-toolbar role="heading" color="accent">
      <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon" (click)="drawer.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
      <mat-icon aria-hidden="false" aria-label="Example home icon">explore</mat-icon>
      <span>Polo IT - La Plata</span>
      <span class="example-spacer"></span>
      <mat-icon aria-hidden="false" aria-label="Example home icon">search</mat-icon>
      <div class="ng-autocomplete">
        <ng-autocomplete 
          [data]="companies"
          [searchKeyword]="filter"
          [itemTemplate]="itemTemplate"
          [notFoundTemplate]="notFoundTemplate"
          notFoundText="No se encontro empresa con ese nombre..."
          placeHolder="Ingrese el nombre de la empresa"
          (selected)="getCompanyFilteredByName($event)"
          (inputChanged)="onChangeNameFilter($event)"
          (inputCleared)="cleanNameFilter()">                                 
        </ng-autocomplete>
         
        <ng-template #itemTemplate let-item>
        <a [innerHTML]="item.name"></a>
        </ng-template>
         
        <ng-template #notFoundTemplate let-notFound>
        <div [innerHTML]="notFound"></div>
        </ng-template>
        </div>
    </mat-toolbar>
    <div *ngIf="!loaded">
      <div>
          <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
      </div>
      <div class="progress">
          <mat-spinner color="accent"></mat-spinner>
      </div>    
    </div>
    <agm-map [latitude]="initial_lat" [longitude]="initial_lng" [zoom]="map_zoom" *ngIf="loaded">
      <agm-marker *ngFor="let company of filtered_companies" [latitude]=company.latitude [longitude]=company.length [title]=company.name [animation]=DROP (markerClick)="onMarkerClick($event)">
        <agm-info-window [disableAutoPan]="true">
          <mat-card class="example-card">
            <mat-card-header>
              <div mat-card-avatar class="example-header-image"></div>
              <mat-card-title>{{company.name}}</mat-card-title>
              <mat-card-subtitle><a *ngIf="company.url_website != '-'" target="_blank" href={{company.url_website}}>{{company.url_website}}</a></mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
              <p *ngIf="company.description != '-'">
                {{company.description}}
              </p><br>
              <span *ngIf="company.addres != '-'"><em>Direccion:</em> {{company.addres}}</span><br>
              <span *ngIf="company.type != '-'"><em>Tipo:</em> {{company.type}}</span><br><br>
              <div *ngIf="company.owner_name != '-' || company.owner_email != '-'">
                <span class="data">Dueño</span><br><br>
                <span *ngIf="company.owner_name != '-'"><em>Nombre:</em> {{company.owner_name}}</span><br>
                <span *ngIf="company.owner_email != '-'"><em>email:</em> {{company.owner_email}}</span>
              </div>
            </mat-card-content>
          </mat-card>
        </agm-info-window>
      </agm-marker>
    </agm-map>
  </div>
</mat-drawer-container>





